
import { showLoadingToast, closeToast } from 'vant'

export function request(request, options = {
  lodingMessage: 'Please Wait'
}) {
  return new Promise(async (resolove, reject) => {
    showLoadingToast({
      duration: 0,
      forbidClick: true,
      message: options.lodingMessage
    })
    let response
    let errorMessage
    try {
      response = await request()
    } catch (error) {
      errorMessage = error.toString()
    } finally {
      closeToast()
      if (response && response.code === 0) {
        resolove(response.data)
      } else {
        reject(errorMessage || response.msg)
      }
    }
  })
}

export function getAppPackage() {
  const userAgent = navigator.userAgent
  const subUserAgens = userAgent.split('#^&')
  if (subUserAgens && subUserAgens.length > 2) {
    return subUserAgens[subUserAgens.length - 1]
  }
  return 'com.hfun.h5'
}

export function getAppToken() {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.indexOf('android') !== -1) {  
    const subUserAgens = userAgent.split('#^&')
    if (subUserAgens.length > 2) {
      return Client.getToken()
    }
    return Client.feedbackToken()
  } else if (userAgent.indexOf('ios') !== -1) {
    return window.prompt('getToken')
  }
  // 判断是否是安卓设备
  // if (userAgent.indexOf('android') === -1) {
  //   return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImFwcCI6ImNvbS5oZnVuLmFuY2hvci5iYyIsImdlbmRlciI6bnVsbCwiaWRlbnRpdHkiOjIsImlkIjoxNjUwNDMzODgyMzA3MjE5NDU3fSwiZXhwIjoxNjg4NTQyMTYzfQ.Koakpsfv18toBUjhHczZiQbHu2PrlaT-t7Gzi59VnqM'
  // }
  return ''
}

export function feedback(content) {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.indexOf('android') !== -1) {  
    Client.feedback(content)
  } else if (userAgent.indexOf('ios') !== -1) {
    window.webkit.messageHandlers.feedback.postMessage(content)
  }
}

export function paySuccess(content) {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('android') !== -1) {  
    const subUserAgens = userAgent.split('#^&')
    if (subUserAgens.length > 2) {
      return Client.paySuccess(JSON.stringify(content))
    }
  }
}

export function goToRecharge(source = 'OTHER') {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('android') !== -1) {  
    const subUserAgens = userAgent.split('#^&')
    if (subUserAgens.length > 2) {
      return Client.goToRecharge(JSON.stringify({
        source
      }))
    }
  }
}

export function getSafeAreaTop() {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('android') !== -1) {  
    const subUserAgens = userAgent.split('#^&')
    if (subUserAgens.length > 2) {
      try {
        return Client.getBarHigh() || 0
      } catch(e) {
      }
    }
  }
  return 40
}

